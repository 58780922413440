var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { extendTheme, theme as theme$1 } from "@chakra-ui/react";
import { mode, darken, transparentize, getColor } from "@chakra-ui/theme-tools";
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
(function(module, exports) {
  (function(global2, factory) {
    factory();
  })(commonjsGlobal, function() {
    function applyFocusVisiblePolyfill(scope) {
      var hadKeyboardEvent = true;
      var hadFocusVisibleRecently = false;
      var hadFocusVisibleRecentlyTimeout = null;
      var inputTypesAllowlist = {
        text: true,
        search: true,
        url: true,
        tel: true,
        email: true,
        password: true,
        number: true,
        date: true,
        month: true,
        week: true,
        time: true,
        datetime: true,
        "datetime-local": true
      };
      function isValidFocusTarget(el) {
        if (el && el !== document && el.nodeName !== "HTML" && el.nodeName !== "BODY" && "classList" in el && "contains" in el.classList) {
          return true;
        }
        return false;
      }
      function focusTriggersKeyboardModality(el) {
        var type = el.type;
        var tagName = el.tagName;
        if (tagName === "INPUT" && inputTypesAllowlist[type] && !el.readOnly) {
          return true;
        }
        if (tagName === "TEXTAREA" && !el.readOnly) {
          return true;
        }
        if (el.isContentEditable) {
          return true;
        }
        return false;
      }
      function addFocusVisibleClass(el) {
        if (el.classList.contains("focus-visible")) {
          return;
        }
        el.classList.add("focus-visible");
        el.setAttribute("data-focus-visible-added", "");
      }
      function removeFocusVisibleClass(el) {
        if (!el.hasAttribute("data-focus-visible-added")) {
          return;
        }
        el.classList.remove("focus-visible");
        el.removeAttribute("data-focus-visible-added");
      }
      function onKeyDown(e) {
        if (e.metaKey || e.altKey || e.ctrlKey) {
          return;
        }
        if (isValidFocusTarget(scope.activeElement)) {
          addFocusVisibleClass(scope.activeElement);
        }
        hadKeyboardEvent = true;
      }
      function onPointerDown(e) {
        hadKeyboardEvent = false;
      }
      function onFocus(e) {
        if (!isValidFocusTarget(e.target)) {
          return;
        }
        if (hadKeyboardEvent || focusTriggersKeyboardModality(e.target)) {
          addFocusVisibleClass(e.target);
        }
      }
      function onBlur(e) {
        if (!isValidFocusTarget(e.target)) {
          return;
        }
        if (e.target.classList.contains("focus-visible") || e.target.hasAttribute("data-focus-visible-added")) {
          hadFocusVisibleRecently = true;
          window.clearTimeout(hadFocusVisibleRecentlyTimeout);
          hadFocusVisibleRecentlyTimeout = window.setTimeout(function() {
            hadFocusVisibleRecently = false;
          }, 100);
          removeFocusVisibleClass(e.target);
        }
      }
      function onVisibilityChange(e) {
        if (document.visibilityState === "hidden") {
          if (hadFocusVisibleRecently) {
            hadKeyboardEvent = true;
          }
          addInitialPointerMoveListeners();
        }
      }
      function addInitialPointerMoveListeners() {
        document.addEventListener("mousemove", onInitialPointerMove);
        document.addEventListener("mousedown", onInitialPointerMove);
        document.addEventListener("mouseup", onInitialPointerMove);
        document.addEventListener("pointermove", onInitialPointerMove);
        document.addEventListener("pointerdown", onInitialPointerMove);
        document.addEventListener("pointerup", onInitialPointerMove);
        document.addEventListener("touchmove", onInitialPointerMove);
        document.addEventListener("touchstart", onInitialPointerMove);
        document.addEventListener("touchend", onInitialPointerMove);
      }
      function removeInitialPointerMoveListeners() {
        document.removeEventListener("mousemove", onInitialPointerMove);
        document.removeEventListener("mousedown", onInitialPointerMove);
        document.removeEventListener("mouseup", onInitialPointerMove);
        document.removeEventListener("pointermove", onInitialPointerMove);
        document.removeEventListener("pointerdown", onInitialPointerMove);
        document.removeEventListener("pointerup", onInitialPointerMove);
        document.removeEventListener("touchmove", onInitialPointerMove);
        document.removeEventListener("touchstart", onInitialPointerMove);
        document.removeEventListener("touchend", onInitialPointerMove);
      }
      function onInitialPointerMove(e) {
        if (e.target.nodeName && e.target.nodeName.toLowerCase() === "html") {
          return;
        }
        hadKeyboardEvent = false;
        removeInitialPointerMoveListeners();
      }
      document.addEventListener("keydown", onKeyDown, true);
      document.addEventListener("mousedown", onPointerDown, true);
      document.addEventListener("pointerdown", onPointerDown, true);
      document.addEventListener("touchstart", onPointerDown, true);
      document.addEventListener("visibilitychange", onVisibilityChange, true);
      addInitialPointerMoveListeners();
      scope.addEventListener("focus", onFocus, true);
      scope.addEventListener("blur", onBlur, true);
      if (scope.nodeType === Node.DOCUMENT_FRAGMENT_NODE && scope.host) {
        scope.host.setAttribute("data-js-focus-visible", "");
      } else if (scope.nodeType === Node.DOCUMENT_NODE) {
        document.documentElement.classList.add("js-focus-visible");
        document.documentElement.setAttribute("data-js-focus-visible", "");
      }
    }
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      window.applyFocusVisiblePolyfill = applyFocusVisiblePolyfill;
      var event;
      try {
        event = new CustomEvent("focus-visible-polyfill-ready");
      } catch (error) {
        event = document.createEvent("CustomEvent");
        event.initCustomEvent("focus-visible-polyfill-ready", false, false, {});
      }
      window.dispatchEvent(event);
    }
    if (typeof document !== "undefined") {
      applyFocusVisiblePolyfill(document);
    }
  });
})();
const baseStyle$c = {
  textTransform: "normal",
  fontWeight: "medium",
  borderRadius: "2xl"
};
const sizes$7 = {
  lg: {
    fontSize: "sm",
    px: "3",
    py: "1"
  },
  md: {
    fontSize: "sm",
    lineHeight: "1.25rem",
    px: "2.5",
    py: "0.5"
  },
  sm: {
    fontSize: "xs",
    lineHeight: "1.5",
    px: "2",
    py: "0.5"
  }
};
const variants$9 = {
  subtle: (props) => ({
    bg: mode(darken(`${props.colorScheme}.50`, 2)(props.theme), transparentize(`${props.colorScheme}.200`, 0.16)(props.theme))(props)
  })
};
const defaultProps$3 = {
  size: "md",
  variant: "subtle"
};
var badge = {
  baseStyle: baseStyle$c,
  defaultProps: defaultProps$3,
  variants: variants$9,
  sizes: sizes$7
};
const baseStyle$b = {
  ":focus:not(:focus-visible)": {
    boxShadow: "none"
  },
  fontWeight: "medium",
  borderRadius: "lg"
};
const sizes$6 = {
  lg: {
    fontSize: "md"
  },
  xl: {
    h: "3.75rem",
    minW: "3.75rem",
    fontSize: "lg",
    px: 7
  }
};
const variants$8 = {
  primary: (props) => props.theme.components["Button"]["variants"]["solid"](__spreadProps(__spreadValues({}, props), {
    variant: "solid",
    colorScheme: "brand"
  })),
  "primary-on-accent": () => ({
    bg: "brand.50",
    color: "brand.600",
    _hover: { bg: "brand.100" },
    _active: { bg: "brand.100" }
  }),
  secondary: (props) => props.theme.components["Button"]["variants"]["outline"](__spreadProps(__spreadValues({}, props), {
    variant: "outline",
    colorScheme: "gray"
  })),
  "secondary-on-accent": {
    color: "white",
    borderColor: "brand.50",
    borderWidth: "1px",
    _hover: { bg: "whiteAlpha.200" },
    _active: { bg: "whiteAlpha.200" }
  },
  outline: (props) => ({
    color: "emphasized",
    bg: mode("white", "gray.800")(props),
    _hover: {
      bg: mode(darken("gray.50", 1)(props.theme), transparentize("gray.700", 0.4)(props.theme))(props)
    },
    _checked: {
      bg: mode("gray.100", "gray.700")(props)
    },
    _active: {
      bg: mode("gray.100", "gray.700")(props)
    }
  }),
  ghost: (props) => ({
    color: "emphasized",
    _hover: {
      bg: mode(darken("gray.50", 1)(props.theme), darken("gray.700", 4)(props.theme))(props)
    },
    _active: {
      bg: mode(darken("gray.50", 1)(props.theme), darken("gray.700", 4)(props.theme))(props)
    },
    _activeLink: {
      bg: mode("gray.100", "gray.700")(props)
    }
  }),
  "ghost-on-accent": (props) => ({
    color: "brand.50",
    _hover: {
      bg: transparentize("brand.500", 0.67)(props.theme)
    },
    _activeLink: {
      color: "white",
      bg: "bg-accent-subtle"
    }
  }),
  link: (props) => {
    if (props.colorScheme === "gray") {
      return {
        color: "muted",
        _hover: {
          textDecoration: "none",
          color: "default"
        },
        _active: {
          color: "default"
        }
      };
    }
    return {
      color: mode(`${props.colorScheme}.600`, `${props.colorScheme}.200`)(props),
      _hover: {
        color: mode(`${props.colorScheme}.700`, `${props.colorScheme}.300`)(props),
        textDecoration: "none"
      },
      _active: {
        color: mode(`${props.colorScheme}.700`, `${props.colorScheme}.300`)(props)
      }
    };
  },
  "link-on-accent": () => {
    return {
      padding: 0,
      height: "auto",
      lineHeight: "normal",
      verticalAlign: "baseline",
      color: "brand.50",
      _hover: {
        color: "white"
      },
      _active: {
        color: "white"
      }
    };
  }
};
var button = {
  baseStyle: baseStyle$b,
  variants: variants$8,
  sizes: sizes$6
};
const baseStyle$a = (props) => ({
  label: {
    color: "muted",
    fontWeight: "medium"
  },
  control: {
    bg: mode("white", "gray.800")(props),
    borderRadius: "base"
  }
});
const sizes$5 = {
  md: {
    label: {
      fontSize: "sm"
    }
  }
};
const defaultProps$2 = {
  colorScheme: "brand"
};
var checkbox = {
  baseStyle: baseStyle$a,
  sizes: sizes$5,
  defaultProps: defaultProps$2
};
const baseStyle$9 = {
  _focus: {
    boxShadow: "none"
  },
  _focusVisible: {
    boxShadow: "outline"
  }
};
var closeButton = {
  baseStyle: baseStyle$9
};
const baseStyle$8 = {
  maxW: "7xl",
  px: { base: "4", md: "8" }
};
var container = { baseStyle: baseStyle$8 };
const parts = ["field", "menu", "option"];
const baseStyle$7 = {
  field: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "all 50ms ease"
  },
  option: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  },
  menu: {
    minW: "3xs"
  }
};
const variants$7 = {
  outline: (props) => ({
    menu: {
      bg: mode("white", "gray.800")(props),
      boxShadow: mode("sm", "sm-dark")(props),
      color: "inherit",
      minW: "3xs",
      py: "2",
      borderRadius: "md",
      borderWidth: "1px"
    },
    option: {
      _selected: {
        bg: mode("gray.100", "gray.700")(props)
      }
    },
    field: {
      border: "1px solid",
      borderColor: "inherit",
      bg: mode("white", "gray.800")(props),
      _hover: {
        borderColor: mode("gray.300", "whiteAlpha.400")(props)
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
        borderColor: "inherit"
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _invalid: {
        borderColor: getColor(props.theme, mode("red.500", "red.300")(props)),
        boxShadow: `0 0 0 1px ${getColor(props.theme, mode("red.500", "red.300")(props))}`
      },
      _focus: {
        borderColor: mode("brand.500", "brand.200")(props),
        boxShadow: mode(`0px 0px 0px 1px ${transparentize(`brand.500`, 1)(props.theme)}`, `0px 0px 0px 1px ${transparentize(`brand.200`, 1)(props.theme)}`)(props)
      },
      _expanded: {
        borderColor: mode("brand.500", "brand.200")(props),
        boxShadow: mode(`0px 0px 0px 1px ${transparentize(`brand.500`, 1)(props.theme)}`, `0px 0px 0px 1px ${transparentize(`brand.200`, 1)(props.theme)}`)(props)
      }
    }
  })
};
const sizes$4 = {
  sm: {
    field: {
      px: 3,
      h: 8,
      fontSize: "sm",
      borderRadius: "sm"
    },
    menu: {
      fontSize: "sm",
      borderRadius: "sm"
    },
    option: {
      px: 3,
      h: 8,
      fontSize: "sm"
    }
  },
  md: {
    field: {
      px: 4,
      h: 10,
      fontSize: "md",
      borderRadius: "md"
    },
    menu: {
      fontSize: "md",
      borderRadius: "md"
    },
    option: {
      px: 4,
      h: 10,
      fontSize: "md"
    }
  },
  lg: {
    field: {
      px: 4,
      h: 12,
      fontSize: "lg",
      borderRadius: "md"
    },
    menu: {
      fontSize: "lg",
      borderRadius: "md"
    },
    option: {
      px: 4,
      h: 12,
      fontSize: "lg"
    }
  }
};
var customSelect = {
  parts,
  baseStyle: baseStyle$7,
  variants: variants$7,
  sizes: sizes$4,
  defaultProps: {
    size: "md",
    variant: "outline",
    colorScheme: "brand"
  }
};
var divider = {
  baseStyle: {
    opacity: 1
  }
};
const baseStyle$6 = {
  color: "emphasized",
  mb: "1.5",
  fontSize: "sm"
};
const sizes$3 = {
  sm: {
    _peerPlaceholderShown: {
      fontSize: "sm",
      top: "1.5",
      left: "4"
    }
  },
  md: {
    _peerPlaceholderShown: {
      fontSize: "md",
      top: "2",
      left: "4"
    }
  },
  lg: {
    _peerPlaceholderShown: {
      fontSize: "lg",
      top: "2.5",
      left: "4"
    }
  }
};
const variants$6 = {
  inline: () => ({
    margin: 0,
    minW: "2xs"
  }),
  floating: () => ({
    position: "absolute",
    transition: "all 0.12s ease-in",
    pointerEvents: "none",
    top: "-27px",
    left: "0",
    _peerPlaceholderShown: {
      fontWeight: "normal",
      color: "subtle"
    },
    _peerFocus: {
      fontSize: "sm",
      fontWeight: "medium",
      top: "-27px",
      left: "0",
      color: "muted"
    }
  })
};
const defaultProps$1 = {
  size: "md"
};
var formLabel = {
  baseStyle: baseStyle$6,
  sizes: sizes$3,
  variants: variants$6,
  defaultProps: defaultProps$1
};
const baseStyle$5 = {
  fontWeight: "semibold"
};
const sizes$2 = {
  "2xl": {
    fontSize: "7xl",
    lineHeight: "5.625rem",
    letterSpacing: "tight"
  },
  xl: {
    fontSize: "6xl",
    lineHeight: "4.5rem",
    letterSpacing: "tight"
  },
  lg: {
    fontSize: "5xl",
    lineHeight: "3.75rem",
    letterSpacing: "tight"
  },
  md: {
    fontSize: "4xl",
    lineHeight: "2.75rem",
    letterSpacing: "tight"
  },
  sm: {
    fontSize: "3xl",
    lineHeight: "2.375rem"
  },
  xs: {
    fontSize: "2xl",
    lineHeight: "2rem"
  }
};
var heading = {
  baseStyle: baseStyle$5,
  sizes: sizes$2
};
const variants$5 = {
  outline: (props) => ({
    field: {
      borderRadius: "lg",
      bg: mode("white", "gray.800")(props),
      _hover: { borderColor: mode("gray.300", "gray.600")(props) },
      _focus: {
        borderColor: mode("brand.500", "brand.200")(props),
        boxShadow: mode(`0px 0px 0px 1px ${transparentize(`brand.500`, 1)(props.theme)}`, `0px 0px 0px 1px ${transparentize(`brand.200`, 1)(props.theme)}`)(props)
      }
    },
    addon: {
      borderRadius: "lg",
      bg: mode("gray.50", "gray.700")(props)
    }
  }),
  "outline-on-accent": (props) => ({
    field: {
      bg: "white",
      borderRadius: "lg",
      color: "gray.900",
      borderWidth: "1px",
      borderColor: "brand.50",
      _placeholder: {
        color: "gray.500"
      },
      _hover: {
        borderColor: "brand.100"
      },
      _focus: {
        borderColor: "brand.200",
        boxShadow: `0px 0px 0px 1px ${transparentize(`brand.200`, 1)(props.theme)}`
      }
    }
  }),
  filled: (props) => {
    if (props.colorScheme === "gray") {
      return {
        field: {
          bg: mode("white", "gray.800")(props),
          _hover: {
            borderColor: mode("gray.200", "gray.700")(props),
            bg: mode("white", "gray.700")(props)
          },
          _focus: {
            borderColor: "accent",
            bg: mode("white", "gray.800")(props)
          }
        }
      };
    }
    return {
      field: {
        bg: "bg-accent-subtle",
        color: "on-accent",
        _placeholder: {
          color: "on-accent"
        },
        _hover: {
          borderColor: "brand.400",
          bg: "bg-accent-subtle"
        },
        _focus: {
          bg: "bg-accent-subtle",
          borderColor: "brand.300"
        }
      }
    };
  }
};
var input = {
  variants: variants$5,
  defaultProps: {
    colorScheme: "gray"
  }
};
const variants$4 = {
  menu: () => ({
    borderRadius: "lg",
    _hover: {
      textDecoration: "none",
      bg: "bg-subtle"
    }
  })
};
var link = {
  variants: variants$4
};
const baseStyle$4 = (props) => ({
  content: {
    borderWidth: "1px",
    boxShadow: mode("lg", "lg-dark")(props),
    borderRadius: "lg",
    background: "bg-surface",
    overflow: "hidden"
  }
});
var popover = {
  baseStyle: baseStyle$4
};
const baseStyle$3 = {
  track: {
    borderRadius: "base"
  }
};
const variants$3 = {
  solid: {
    track: {
      bg: "bg-muted"
    }
  },
  "on-accent": {
    track: {
      bg: "transparent"
    },
    filledTrack: {
      bg: "brand.50"
    }
  }
};
const defaultProps = {
  colorScheme: "brand",
  variant: "solid"
};
var progress = {
  variants: variants$3,
  baseStyle: baseStyle$3,
  defaultProps
};
const baseStyle$2 = (props) => ({
  borderWidth: "1px",
  borderRadius: "lg",
  p: "4",
  bg: "bg-surface",
  transitionProperty: "common",
  transitionDuration: "normal",
  _hover: { borderColor: mode("gray.300", "gray.600")(props) },
  _checked: {
    borderColor: mode("brand.500", "brand.200")(props),
    boxShadow: mode(`0px 0px 0px 1px ${transparentize(`brand.500`, 1)(props.theme)}`, `0px 0px 0px 1px ${transparentize(`brand.200`, 1)(props.theme)}`)(props)
  }
});
var radioCard = {
  baseStyle: baseStyle$2
};
const baseStyle$1 = {
  table: {
    bg: "bg-surface",
    whiteSpace: "nowrap"
  },
  th: {
    fontWeight: "medium",
    textTransform: "normal",
    letterSpacing: "normal",
    borderTopWidth: "1px",
    whiteSpace: "nowrap"
  }
};
const variants$2 = {
  simple: (props) => ({
    th: {
      color: "muted",
      bg: mode("gray.50", transparentize("gray.700", 0.4)(props.theme))(props)
    }
  }),
  striped: (props) => ({
    th: {
      color: "muted",
      borderBottomWidth: "0px"
    },
    thead: {
      "th,td": {
        borderWidth: "0px"
      }
    },
    tbody: {
      tr: {
        "th,td": {
          borderWidth: "0px"
        },
        "&:last-of-type": {
          "th, td": {
            borderBottomWidth: "1px"
          }
        },
        "&:nth-of-type(odd)": {
          "th, td": {
            borderBottomWidth: "0px"
          },
          td: {
            bg: mode("gray.50", transparentize("gray.700", 0.4)(props.theme))(props)
          }
        }
      }
    }
  })
};
const sizes$1 = {
  md: {
    th: {
      lineHeight: "1.25rem"
    },
    td: {
      fontSize: "sm"
    }
  }
};
var table = {
  sizes: sizes$1,
  baseStyle: baseStyle$1,
  variants: variants$2
};
const baseStyle = {
  tab: {
    fontWeight: "medium",
    color: "muted",
    _focus: {
      boxShadow: "none"
    },
    _focusVisible: {
      boxShadow: "base"
    }
  }
};
const variants$1 = {
  line: {
    tab: {
      _selected: {
        color: "accent",
        borderColor: "accent"
      },
      _active: {
        bg: "transparent"
      }
    }
  },
  enclosed: {
    tab: {
      _selected: {
        color: "accent"
      }
    }
  }
};
const sizes = {
  md: {
    tab: {
      fontSize: "sm"
    }
  }
};
var tabs = { baseStyle, variants: variants$1, sizes };
const variants = {
  outline: (props) => ({
    borderRadius: "lg",
    bg: mode("white", "gray.800")(props),
    _hover: { borderColor: mode("gray.300", "gray.600")(props) },
    _focus: {
      borderColor: mode("brand.500", "brand.200")(props),
      boxShadow: mode(`0px 0px 0px 1px ${transparentize(`brand.500`, 1)(props.theme)}`, `0px 0px 0px 1px ${transparentize(`brand.200`, 1)(props.theme)}`)(props)
    }
  })
};
var textarea = {
  variants
};
var components = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Badge: badge,
  Button: button,
  Checkbox: checkbox,
  CloseButton: closeButton,
  Container: container,
  CustomSelect: customSelect,
  Divider: divider,
  FormLabel: formLabel,
  Heading: heading,
  Input: input,
  Link: link,
  Popover: popover,
  Progress: progress,
  RadioCard: radioCard,
  Table: table,
  Tabs: tabs,
  Textarea: textarea
}, Symbol.toStringTag, { value: "Module" }));
var fonts = {
  heading: "InterVariable, -apple-system, system-ui, sans-serif",
  body: "InterVariable, -apple-system, system-ui, sans-serif"
};
var shadows = {
  xs: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  sm: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 2px 4px rgba(48, 49, 51, 0.1)",
  md: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1)",
  lg: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1)",
  xl: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1)",
  "xs-dark": "0px 1px 3px rgba(11, 12, 17, 0.9)",
  "sm-dark": "0px 2px 4px rgba(11, 12, 17, 0.9)",
  "md-dark": "0px 4px 8px rgba(11, 12, 17, 0.9)",
  "lg-dark": "0px 8px 16px rgba(11, 12, 17, 0.9)",
  "xl-dark": "0px 16px 24px rgba(11, 12, 17, 0.9)"
};
var styles = {
  global: (props) => ({
    body: {
      color: "default",
      bg: "bg-canvas"
    },
    "*::placeholder": {
      opacity: 1,
      color: "subtle"
    },
    "*, *::before, &::after": {
      borderColor: mode("gray.200", "gray.700")(props)
    }
  })
};
var tokens = {
  colors: {
    "bg-canvas": {
      default: "gray.50",
      _dark: "gray.900"
    },
    "bg-surface": {
      default: "white",
      _dark: "gray.800"
    },
    "bg-subtle": {
      default: "gray.50",
      _dark: "gray.700"
    },
    "bg-muted": {
      default: "gray.100",
      _dark: "gray.600"
    },
    default: {
      default: "gray.900",
      _dark: "white"
    },
    inverted: {
      default: "white",
      _dark: "gray.900"
    },
    emphasized: {
      default: "gray.700",
      _dark: "gray.100"
    },
    muted: {
      default: "gray.600",
      _dark: "gray.300"
    },
    subtle: {
      default: "gray.500",
      _dark: "gray.400"
    },
    border: {
      default: "gray.200",
      _dark: "gray.700"
    },
    accent: {
      default: "brand.500",
      _dark: "brand.200"
    },
    success: {
      default: "green.600",
      _dark: "green.200"
    },
    error: {
      default: "red.600",
      _dark: "red.200"
    },
    "bg-accent": "brand.600",
    "bg-accent-subtle": "brand.500",
    "bg-accent-muted": "brand.400",
    "on-accent": "white",
    "on-accent-muted": "brand.50",
    "on-accent-subtle": "brand.100"
  }
};
var foundations = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  fonts,
  shadows,
  styles,
  semanticTokens: tokens
}, Symbol.toStringTag, { value: "Module" }));
const theme = extendTheme(__spreadProps(__spreadValues({}, foundations), {
  components: __spreadValues({}, components),
  colors: __spreadProps(__spreadValues({}, theme$1.colors), { brand: theme$1.colors.blue })
}));
export { theme };
