import { mergeRefs } from '@chakra-ui/react-utils';
import { createPopper } from '@popperjs/core';
import { useRef, useCallback, useEffect } from 'react';

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var toVar = function toVar(value, fallback) {
  return {
    "var": value,
    varRef: fallback ? "var(" + value + ", " + fallback + ")" : "var(" + value + ")"
  };
};

var cssVars = {
  arrowShadowColor: toVar("--popper-arrow-shadow-color"),
  arrowSize: toVar("--popper-arrow-size", "8px"),
  arrowSizeHalf: toVar("--popper-arrow-size-half"),
  arrowBg: toVar("--popper-arrow-bg"),
  transformOrigin: toVar("--popper-transform-origin"),
  arrowOffset: toVar("--popper-arrow-offset")
};
function getBoxShadow(placement) {
  if (placement.includes("top")) return "1px 1px 1px 0 var(--popper-arrow-shadow-color)";
  if (placement.includes("bottom")) return "-1px -1px 1px 0 var(--popper-arrow-shadow-color)";
  if (placement.includes("right")) return "-1px 1px 1px 0 var(--popper-arrow-shadow-color)";
  if (placement.includes("left")) return "1px -1px 1px 0 var(--popper-arrow-shadow-color)";
}
var transforms = {
  top: "bottom center",
  "top-start": "bottom left",
  "top-end": "bottom right",
  bottom: "top center",
  "bottom-start": "top left",
  "bottom-end": "top right",
  left: "right center",
  "left-start": "right top",
  "left-end": "right bottom",
  right: "left center",
  "right-start": "left top",
  "right-end": "left bottom"
};
var toTransformOrigin = function toTransformOrigin(placement) {
  return transforms[placement];
};
var defaultEventListeners = {
  scroll: true,
  resize: true
};
function getEventListenerOptions(value) {
  var eventListeners;

  if (typeof value === "object") {
    eventListeners = {
      enabled: true,
      options: _extends({}, defaultEventListeners, value)
    };
  } else {
    eventListeners = {
      enabled: value,
      options: defaultEventListeners
    };
  }

  return eventListeners;
}

/* -------------------------------------------------------------------------------------------------
 The match width modifier sets the popper width to match the reference.
 It is useful for custom selects, autocomplete, etc.
* -----------------------------------------------------------------------------------------------*/

var matchWidth = {
  name: "matchWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: function fn(_ref) {
    var state = _ref.state;
    state.styles.popper.width = state.rects.reference.width + "px";
  },
  effect: function effect(_ref2) {
    var state = _ref2.state;
    return function () {
      var reference = state.elements.reference;
      state.elements.popper.style.width = reference.offsetWidth + "px";
    };
  }
};
/* -------------------------------------------------------------------------------------------------
  The transform origin modifier sets the css `transformOrigin` value of the popper
  based on the dynamic placement state of the popper.
  
  Useful when we need to animate/transition the popper.
* -----------------------------------------------------------------------------------------------*/

var transformOrigin = {
  name: "transformOrigin",
  enabled: true,
  phase: "write",
  fn: function fn(_ref3) {
    var state = _ref3.state;
    setTransformOrigin(state);
  },
  effect: function effect(_ref4) {
    var state = _ref4.state;
    return function () {
      setTransformOrigin(state);
    };
  }
};

var setTransformOrigin = function setTransformOrigin(state) {
  state.elements.popper.style.setProperty(cssVars.transformOrigin["var"], toTransformOrigin(state.placement));
};
/* -------------------------------------------------------------------------------------------------
  The position arrow modifier adds width, height and overrides the `top/left/right/bottom`
  styles generated by popper.js to properly position the arrow
* -----------------------------------------------------------------------------------------------*/


var positionArrow = {
  name: "positionArrow",
  enabled: true,
  phase: "afterWrite",
  fn: function fn(_ref5) {
    var state = _ref5.state;
    setArrowStyles(state);
  }
};

var setArrowStyles = function setArrowStyles(state) {
  var _state$elements;

  if (!state.placement) return;
  var overrides = getArrowStyle$1(state.placement);

  if ((_state$elements = state.elements) != null && _state$elements.arrow && overrides) {
    var _Object$assign, _vars;

    Object.assign(state.elements.arrow.style, (_Object$assign = {}, _Object$assign[overrides.property] = overrides.value, _Object$assign.width = cssVars.arrowSize.varRef, _Object$assign.height = cssVars.arrowSize.varRef, _Object$assign.zIndex = -1, _Object$assign));
    var vars = (_vars = {}, _vars[cssVars.arrowSizeHalf["var"]] = "calc(" + cssVars.arrowSize.varRef + " / 2)", _vars[cssVars.arrowOffset["var"]] = "calc(" + cssVars.arrowSizeHalf.varRef + " * -1)", _vars);

    for (var property in vars) {
      state.elements.arrow.style.setProperty(property, vars[property]);
    }
  }
};

var getArrowStyle$1 = function getArrowStyle(placement) {
  if (placement.startsWith("top")) {
    return {
      property: "bottom",
      value: cssVars.arrowOffset.varRef
    };
  }

  if (placement.startsWith("bottom")) {
    return {
      property: "top",
      value: cssVars.arrowOffset.varRef
    };
  }

  if (placement.startsWith("left")) {
    return {
      property: "right",
      value: cssVars.arrowOffset.varRef
    };
  }

  if (placement.startsWith("right")) {
    return {
      property: "left",
      value: cssVars.arrowOffset.varRef
    };
  }
};
/* -------------------------------------------------------------------------------------------------
  The inner arrow modifier, sets the placement styles for the inner arrow that forms
  the popper arrow tip.
* -----------------------------------------------------------------------------------------------*/


var innerArrow = {
  name: "innerArrow",
  enabled: true,
  phase: "main",
  requires: ["arrow"],
  fn: function fn(_ref6) {
    var state = _ref6.state;
    setInnerArrowStyles(state);
  },
  effect: function effect(_ref7) {
    var state = _ref7.state;
    return function () {
      setInnerArrowStyles(state);
    };
  }
};

var setInnerArrowStyles = function setInnerArrowStyles(state) {
  if (!state.elements.arrow) return;
  var inner = state.elements.arrow.querySelector("[data-popper-arrow-inner]");
  if (!inner) return;
  Object.assign(inner.style, {
    transform: "rotate(45deg)",
    background: cssVars.arrowBg.varRef,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: "inherit",
    boxShadow: getBoxShadow(state.placement)
  });
};

var logicals = {
  "start-start": {
    ltr: "left-start",
    rtl: "right-start"
  },
  "start-end": {
    ltr: "left-end",
    rtl: "right-end"
  },
  "end-start": {
    ltr: "right-start",
    rtl: "left-start"
  },
  "end-end": {
    ltr: "right-end",
    rtl: "left-end"
  },
  start: {
    ltr: "left",
    rtl: "right"
  },
  end: {
    ltr: "right",
    rtl: "left"
  }
};
var opposites = {
  "auto-start": "auto-end",
  "auto-end": "auto-start",
  "top-start": "top-end",
  "top-end": "top-start",
  "bottom-start": "bottom-end",
  "bottom-end": "bottom-start"
};
function getPopperPlacement(placement, dir) {
  var _placement, _placement2;

  if (dir === void 0) {
    dir = "ltr";
  }

  var value = ((_placement = logicals[placement]) == null ? void 0 : _placement[dir]) || placement;
  if (dir === "ltr") return value;
  return (_placement2 = opposites[placement]) != null ? _placement2 : value;
}

var _excluded = ["size", "shadowColor", "bg", "style"];
function usePopper(props) {
  if (props === void 0) {
    props = {};
  }

  var _props = props,
      _props$enabled = _props.enabled,
      enabled = _props$enabled === void 0 ? true : _props$enabled,
      modifiers = _props.modifiers,
      _props$placement = _props.placement,
      placementProp = _props$placement === void 0 ? "bottom" : _props$placement,
      _props$strategy = _props.strategy,
      strategy = _props$strategy === void 0 ? "absolute" : _props$strategy,
      _props$arrowPadding = _props.arrowPadding,
      arrowPadding = _props$arrowPadding === void 0 ? 8 : _props$arrowPadding,
      _props$eventListeners = _props.eventListeners,
      eventListeners = _props$eventListeners === void 0 ? true : _props$eventListeners,
      offset = _props.offset,
      _props$gutter = _props.gutter,
      gutter = _props$gutter === void 0 ? 8 : _props$gutter,
      _props$flip = _props.flip,
      flip = _props$flip === void 0 ? true : _props$flip,
      _props$boundary = _props.boundary,
      boundary = _props$boundary === void 0 ? "clippingParents" : _props$boundary,
      _props$preventOverflo = _props.preventOverflow,
      preventOverflow = _props$preventOverflo === void 0 ? true : _props$preventOverflo,
      matchWidth$1 = _props.matchWidth,
      _props$direction = _props.direction,
      direction = _props$direction === void 0 ? "ltr" : _props$direction;
  var reference = useRef(null);
  var popper = useRef(null);
  var instance = useRef(null);
  var placement = getPopperPlacement(placementProp, direction);
  var cleanup = useRef(function () {});
  var setupPopper = useCallback(function () {
    if (!enabled || !reference.current || !popper.current) return; // If popper instance exists, destroy it, so we can create a new one

    cleanup.current == null ? void 0 : cleanup.current();
    instance.current = createPopper(reference.current, popper.current, {
      placement: placement,
      modifiers: [innerArrow, positionArrow, transformOrigin, _extends({}, matchWidth, {
        enabled: !!matchWidth$1
      }), _extends({
        name: "eventListeners"
      }, getEventListenerOptions(eventListeners)), {
        name: "arrow",
        options: {
          padding: arrowPadding
        }
      }, {
        name: "offset",
        options: {
          offset: offset != null ? offset : [0, gutter]
        }
      }, {
        name: "flip",
        enabled: !!flip,
        options: {
          padding: 8
        }
      }, {
        name: "preventOverflow",
        enabled: !!preventOverflow,
        options: {
          boundary: boundary
        }
      }].concat(modifiers != null ? modifiers : []),
      strategy: strategy
    }); // force update one-time to fix any positioning issues

    instance.current.forceUpdate();
    cleanup.current = instance.current.destroy;
  }, [placement, enabled, modifiers, matchWidth$1, eventListeners, arrowPadding, offset, gutter, flip, preventOverflow, boundary, strategy]);
  useEffect(function () {
    return function () {
      /**
       * Fast refresh might call this function and tear down the popper
       * even if the reference still exists. This checks against that
       */
      if (!reference.current && !popper.current) {
        var _instance$current;

        (_instance$current = instance.current) == null ? void 0 : _instance$current.destroy();
        instance.current = null;
      }
    };
  }, []);
  var referenceRef = useCallback(function (node) {
    reference.current = node;
    setupPopper();
  }, [setupPopper]);
  var getReferenceProps = useCallback(function (props, ref) {
    if (props === void 0) {
      props = {};
    }

    if (ref === void 0) {
      ref = null;
    }

    return _extends({}, props, {
      ref: mergeRefs(referenceRef, ref)
    });
  }, [referenceRef]);
  var popperRef = useCallback(function (node) {
    popper.current = node;
    setupPopper();
  }, [setupPopper]);
  var getPopperProps = useCallback(function (props, ref) {
    if (props === void 0) {
      props = {};
    }

    if (ref === void 0) {
      ref = null;
    }

    return _extends({}, props, {
      ref: mergeRefs(popperRef, ref),
      style: _extends({}, props.style, {
        position: strategy,
        minWidth: matchWidth$1 ? undefined : "max-content",
        inset: "0 auto auto 0"
      })
    });
  }, [strategy, popperRef, matchWidth$1]);
  var getArrowProps = useCallback(function (props, ref) {
    if (props === void 0) {
      props = {};
    }

    if (ref === void 0) {
      ref = null;
    }

    var _props2 = props;
        _props2.size;
        _props2.shadowColor;
        _props2.bg;
        _props2.style;
        var rest = _objectWithoutPropertiesLoose(_props2, _excluded);

    return _extends({}, rest, {
      ref: ref,
      "data-popper-arrow": "",
      style: getArrowStyle(props)
    });
  }, []);
  var getArrowInnerProps = useCallback(function (props, ref) {
    if (props === void 0) {
      props = {};
    }

    if (ref === void 0) {
      ref = null;
    }

    return _extends({}, props, {
      ref: ref,
      "data-popper-arrow-inner": ""
    });
  }, []);
  return {
    update: function update() {
      var _instance$current2;

      (_instance$current2 = instance.current) == null ? void 0 : _instance$current2.update();
    },
    forceUpdate: function forceUpdate() {
      var _instance$current3;

      (_instance$current3 = instance.current) == null ? void 0 : _instance$current3.forceUpdate();
    },
    transformOrigin: cssVars.transformOrigin.varRef,
    referenceRef: referenceRef,
    popperRef: popperRef,
    getPopperProps: getPopperProps,
    getArrowProps: getArrowProps,
    getArrowInnerProps: getArrowInnerProps,
    getReferenceProps: getReferenceProps
  };
}

function getArrowStyle(props) {
  var size = props.size,
      shadowColor = props.shadowColor,
      bg = props.bg,
      style = props.style;

  var computedStyle = _extends({}, style, {
    position: "absolute"
  });

  if (size) {
    computedStyle["--popper-arrow-size"] = size;
  }

  if (shadowColor) {
    computedStyle["--popper-arrow-shadow-color"] = shadowColor;
  }

  if (bg) {
    computedStyle["--popper-arrow-bg"] = bg;
  }

  return computedStyle;
}

export { cssVars as popperCSSVars, usePopper };
